// RSVPModal.tsx
import React from 'react';
import { observer } from 'mobx-react-lite';
import rsvpStore from '../../stores/RsvpStore';

interface RSVPModalProps {
  eventId: number;
  isOpen: boolean;
  onClose: () => void;
}

const RSVPModal: React.FC<RSVPModalProps> = observer(({ eventId, isOpen, onClose }) => {
  if (!isOpen) return null;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await rsvpStore.submitRsvp(eventId);
    if (rsvpStore.success) {
      onClose();
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-md p-6 text-darkBrown">
        <h2 className="text-2xl font-bold mb-4 text-center">RSVP for Event</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700">Name:</label>
            <input
              type="text"
              value={rsvpStore.name}
              onChange={(e) => rsvpStore.setName(e.target.value)}
              required
              className="w-full border border-gray-300 p-2 rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Email:</label>
            <input
              type="email"
              value={rsvpStore.email}
              onChange={(e) => rsvpStore.setEmail(e.target.value)}
              required
              className="w-full border border-gray-300 p-2 rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Phone Number:</label>
            <input
              type="text"
              value={rsvpStore.phone_number}
              onChange={(e) => rsvpStore.setPhoneNumber(e.target.value)}
              className="w-full border border-gray-300 p-2 rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Number of Guests (including yourself):</label>
            <input
              type="number"
              value={rsvpStore.guests}
              onChange={(e) => rsvpStore.setGuests(parseInt(e.target.value) || 1)}
              min="1"
              required
              className="w-full border border-gray-300 p-2 rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Message:</label>
            <textarea
              value={rsvpStore.message}
              onChange={(e) => rsvpStore.setMessage(e.target.value)}
              className="w-full border border-gray-300 p-2 rounded"
            />
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              onClick={() => { rsvpStore.reset(); onClose(); }}
              className="px-4 py-2 bg-gray-300 text-gray-700 rounded mr-2 hover:bg-gray-400"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={rsvpStore.isSubmitting}
              className="px-4 py-2 bg-maroon text-white rounded hover:bg-olive"
            >
              {rsvpStore.isSubmitting ? 'Submitting...' : 'Submit RSVP'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
});

export default RSVPModal;