// src/components/TestimonialsSection.tsx

import React from 'react';
import { ReactComponent as OntoTheNextOne } from '../../assets/images/OntoTheNextOne.svg'; // Adjust the path as necessary

const TestimonialsSection: React.FC = () => {
    const testimonials = [
        {
            text: "Great place for happy hour with wine & cheese or for dinner in a nice casual romantic atmosphere.",
            author: "Laura Forster",
        },       
        {
            text: "I had a great time at this Italian wine bar! The cozy vibe is perfect for hanging out with friends, and Leslie was a wonderful server—super friendly and attentive. They have a solid wine selection, and don't miss out on Pasta Thursdays; it's a delicious combo. Definitely a fun spot to relax and enjoy some good food and wine!",
            author: "Amy Young",
        },
        {
            text: "Love the decor: reminds me of a little place in Italy. Which is probably the point. When we went, the food bites were very tasty, but it's mainly about the wine!",
            author: "Randy Wilson",
        },
    ];

    return (
        <section className="bg-maroon relative">
            <div className="max-w-6xl mx-auto px-4 py-12">
                <div className="flex flex-col md:flex-row">
                    {testimonials.map((testimonial, index) => (
                        <div
                            key={index}
                            className={`flex-1 text-cream font-recia text-center p-6 my-4 flex flex-col justify-center ${
                                index !== 0 ? 'md:border-l-2 border-gold' : ''
                            }`}
                        >
                            <p className="text-lg md:text-xl italic">
                                "{testimonial.text}" <br /><br /> - {testimonial.author}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
            {/* SVG Transition */}
            <div className="w-full">
                <OntoTheNextOne className="absolute w-full h-auto -bottom-[3em] sm:-bottom-[5em] md:-bottom-[8em] lg:-bottom-[9em] xl:lg:-bottom-[11em]" aria-label="Transition to Next Section" />
            </div>
        </section>
    );
};

export default TestimonialsSection;