// src/components/Events/BookingModal.tsx

import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";

// Define the types for form data
type FormData = {
  email: string;
  date: Date | null;
  numberOfGuests: number;
  catering: boolean;
  eventType: string;
  specialRequests: string;
};

// Define the props for the modal
type BookingModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

// Define the validation schema using Yup
const schema: yup.ObjectSchema<FormData> = yup.object().shape({
    email: yup.string().email("Invalid email").required("Email is required"),
    date: yup.date().nullable().required("Date is required"),
    numberOfGuests: yup
      .number()
      .typeError("Number of guests must be a number")
      .min(1, "At least one guest")
      .required("Number of guests is required"),
    catering: yup.boolean().required("Catering selection is required"), // Updated line
    eventType: yup.string().required("Event type is required"),
    specialRequests: yup.string().required("Please enter a note."),
  });

// Custom styles for the modal
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "600px",
    width: "90%",
    padding: "0",
    border: "none",
    borderRadius: "8px",
    overflow: "hidden",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1000,
  },
};

// Ensure accessibility by binding the modal to the app element
ReactModal.setAppElement("#root");

const BookingModal: React.FC<BookingModalProps> = ({ isOpen, onClose }) => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [availableDates, setAvailableDates] = useState<Date[]>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  // Initialize the form
  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
      date: null,
      numberOfGuests: 1,
      catering: false,
      eventType: "",
      specialRequests: "",
    },
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    reset,
  } = methods;

  // Fetch unavailable dates when the modal opens
  useEffect(() => {
    if (isOpen) {
      // Fetch booked and conflicting dates from the server
      axios
        .get("/api/events/booked-dates") // Adjust the endpoint as necessary
        .then((response) => {
          // Assume the response data is an array of date strings
          const booked = response.data.bookedDates.map(
            (dateStr: string) => new Date(dateStr)
          );
          setAvailableDates(booked);
        })
        .catch((error) => {
          console.error("Error fetching booked dates:", error);
          // Handle error appropriately, e.g., show a notification
          alert("Failed to load available dates. Please try again later.");
        });
    } else {
      setCurrentStep(1);
      reset();
    }
  }, [isOpen, reset]);

  // Function to check if a date is unavailable
  const isDateUnavailable = (date: Date) => {
    return availableDates.some(
      (bookedDate) =>
        bookedDate.getFullYear() === date.getFullYear() &&
        bookedDate.getMonth() === date.getMonth() &&
        bookedDate.getDate() === date.getDate()
    );
  };

  // Handle form submission
  const onSubmit: SubmitHandler<FormData> = async (data) => {
    try {
      if (currentStep === 1) {
        // Proceed to Step 2
        setCurrentStep(2);
      } else if (currentStep === 2) {
        // Proceed to Step 3
        setCurrentStep(3);
      } else if (currentStep === 3) {
        // Final step: Submit booking
        setIsSubmitting(true);
        const response = await axios.post("/api/events/book", data);
        if (response.status === 200) {
          // Handle successful booking, e.g., redirect to Stripe
          window.location.href = response.data.stripeUrl;
        } else {
          alert("There was an issue processing your booking. Please try again.");
        }
      }
    } catch (error) {
      console.error("Error submitting booking:", error);
      // Handle error appropriately, e.g., show a notification
      alert("There was an error processing your booking. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  // Handle the "Get a Quote" action
  const handleGetQuote = async () => {
    const data = methods.getValues();
    try {
      // Send the data to the server for a quote
      await axios.post("/api/events/get-quote", data);
      // Notify the user of success
      alert("Your request for a quote has been submitted. We will contact you soon.");
      onClose();
      reset();
    } catch (error) {
      console.error("Error getting a quote:", error);
      // Handle error appropriately
      alert("There was an error submitting your request. Please try again.");
    }
  };

  // Handle the "Cancel" action
  const handleCancel = () => {
    onClose();
    reset();
    setCurrentStep(1);
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={handleCancel}
      style={customStyles}
      contentLabel="Booking Modal"
    >
      <div className="bg-white p-6 relative">
        <button
          onClick={handleCancel}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 focus:outline-none"
          aria-label="Close Booking Modal"
        >
          ✕
        </button>
        <h2 className="text-2xl font-bold mb-4 text-center">Book Your Private Event</h2>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* Step Indicators */}
            <div className="flex justify-center mb-6">
              <div
                className={`w-3 h-3 rounded-full ${
                  currentStep === 1 ? "bg-maroon" : "bg-gray-300"
                } mx-1`}
                aria-hidden="true"
              ></div>
              <div
                className={`w-3 h-3 rounded-full ${
                  currentStep === 2 ? "bg-maroon" : "bg-gray-300"
                } mx-1`}
                aria-hidden="true"
              ></div>
              <div
                className={`w-3 h-3 rounded-full ${
                  currentStep === 3 ? "bg-maroon" : "bg-gray-300"
                } mx-1`}
                aria-hidden="true"
              ></div>
            </div>

            {/* Step 1: Email Address */}
            {currentStep === 1 && (
              <div>
                <label className="block mb-2 font-semibold">Email Address</label>
                <input
                  type="email"
                  {...register("email")}
                  className={`w-full border ${
                    errors.email ? "border-red-500" : "border-gray-300"
                  } p-2 rounded mb-2 focus:outline-none focus:ring-2 focus:ring-maroon`}
                  placeholder="you@example.com"
                  required
                />
                {errors.email && (
                  <p className="text-red-500 text-sm">{errors.email.message}</p>
                )}
              </div>
            )}

            {/* Step 2: Select Date */}
            {currentStep === 2 && (
              <div>
                <label className="block mb-2 font-semibold">Select Date</label>
                <DatePicker
                  selected={watch("date")}
                  onChange={(date: Date | null) => setValue("date", date)}
                  filterDate={(date) => !isDateUnavailable(date)}
                  className={`w-full border ${
                    errors.date ? "border-red-500" : "border-gray-300"
                  } p-2 rounded mb-2 focus:outline-none focus:ring-2 focus:ring-maroon`}
                  placeholderText="Choose a date"
                  minDate={new Date()}
                  dateFormat="MMMM d, yyyy"
                  required
                />
                {errors.date && (
                  <p className="text-red-500 text-sm">{errors.date.message}</p>
                )}
              </div>
            )}

            {/* Step 3: Event Details */}
            {currentStep === 3 && (
              <div>
                <label className="block mb-2 font-semibold">Number of Guests</label>
                <input
                  type="number"
                  {...register("numberOfGuests")}
                  className={`w-full border ${
                    errors.numberOfGuests ? "border-red-500" : "border-gray-300"
                  } p-2 rounded mb-2 focus:outline-none focus:ring-2 focus:ring-maroon`}
                  min={1}
                  required
                />
                {errors.numberOfGuests && (
                  <p className="text-red-500 text-sm">{errors.numberOfGuests.message}</p>
                )}

                <label className="flex items-center mb-4">
                  <input
                    type="checkbox"
                    {...register("catering")}
                    className="mr-2"
                  />
                  Would you like catering services?
                </label>

                <label className="block mb-2 font-semibold">Type of Event</label>
                <select
                  {...register("eventType")}
                  className={`w-full border ${
                    errors.eventType ? "border-red-500" : "border-gray-300"
                  } p-2 rounded mb-2 focus:outline-none focus:ring-2 focus:ring-maroon`}
                  required
                >
                  <option value="">Select an event type</option>
                  <option value="birthday">Birthday Celebration</option>
                  <option value="corporate">Corporate Gathering</option>
                  <option value="wedding">Wedding</option>
                  <option value="anniversary">Anniversary</option>
                  <option value="other">Other</option>
                </select>
                {errors.eventType && (
                  <p className="text-red-500 text-sm">{errors.eventType.message}</p>
                )}

                <label className="block mb-2 font-semibold">Special Requests</label>
                <textarea
                  {...register("specialRequests")}
                  className="w-full border border-gray-300 p-2 rounded mb-2 focus:outline-none focus:ring-2 focus:ring-maroon"
                  placeholder="Any special requests or notes..."
                ></textarea>
              </div>
            )}

            {/* Navigation Buttons */}
            <div className="flex justify-between mt-6">
              {currentStep > 1 && (
                <button
                  type="button"
                  onClick={() => setCurrentStep(currentStep - 1)}
                  className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400 transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-gray-400"
                >
                  Back
                </button>
              )}
              <div className="ml-auto flex space-x-2">
                {currentStep < 3 && (
                  <button
                    type="submit"
                    className="bg-maroon text-cream px-4 py-2 rounded hover:bg-olive transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-maroon"
                  >
                    Next
                  </button>
                )}
                {currentStep === 3 && (
                  <>
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className={`bg-maroon text-cream px-4 py-2 rounded hover:bg-olive transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-maroon ${
                        isSubmitting ? "opacity-50 cursor-not-allowed" : ""
                      }`}
                    >
                      {isSubmitting ? "Processing..." : "Book & Pay Deposit"}
                    </button>
                    <button
                      type="button"
                      onClick={handleGetQuote}
                      className="bg-gold text-darkBrown px-4 py-2 rounded hover:bg-olive transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-olive"
                    >
                      Get a Quote
                    </button>
                    <button
                      type="button"
                      onClick={handleCancel}
                      className="bg-gray-200 text-gray-700 px-4 py-2 rounded hover:bg-gray-300 transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-gray-300"
                    >
                      Cancel
                    </button>
                  </>
                )}
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </ReactModal>
  );
};

export default BookingModal;