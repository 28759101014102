// src/components/CollectibleWines/CollectiblePurchaseSuccess.tsx

import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

const CollectiblePurchaseSuccess: React.FC = observer(() => {
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get('session_id');

  useEffect(() => {
    if (sessionId) {
      // Optionally, fetch session details if needed
      // For example, you might want to confirm the purchase or display order details
      // collectibleWineStore.fetchPurchaseDetails(sessionId);
    }
  }, [sessionId]);

  return (
    <div className="min-h-screen bg-cream flex items-center justify-center pt-[20vh]">
      <div className="max-w-xl mx-auto p-6 bg-cream shadow-md rounded">
        <h1 className="text-2xl font-bold mb-4 text-center text-maroon font-recia">Thank You for Your Purchase!</h1>
        <p className="text-lg text-center text-maroon font-satoshi">
          Your purchase was successful. A confirmation email has been sent to your email address.
        </p>
        <div className="mt-6 text-center">
          <a href="/collectible-wine" className="text-maroon underline font-satoshi">
            Back to Collectible Wine
          </a>
        </div>
      </div>
    </div>
  );
});

export default CollectiblePurchaseSuccess;