// src/components/PrivateEvents.tsx

import React, { useState } from "react";
import { Link } from "react-router-dom";
import GreenGrapes from "../../assets/images/GreenGrapes.svg";
import { ReactComponent as WineStainHolder } from "../../assets/images/WineStainHolder.svg";
import BookingModal from "./BookingModal";

const PrivateEvents: React.FC = () => {
  const [isBookingModalOpen, setBookingModalOpen] = useState(false);

  return (
    <section className="w-full bg-cream text-maroon relative">
      {/* Green Grapes full width */}
      <div className="w-full">
        <img
          src={GreenGrapes}
          alt="Green Grapes Divider"
          className="relative -top-[3em] w-full h-auto block"
        />
      </div>

      <div className="px-4 sm:px-6 md:px-8 lg:px-16 py-8 md:py-16">
        <div className="flex flex-col md:flex-row md:items-start md:justify-between">
          {/* Left Column: Title & Text */}
          <div className="md:w-1/2 flex flex-col items-center order-2 md:order-2">
            <div className="w-full text-center mb-8">
              {/* Smaller lines above and below the title */}
              <hr className="border-maroon w-1/3 mx-auto mb-12" />
              <h2 className="text-4xl md:text-5xl leading-tight uppercase text-maroon">
                <span className="font-satoshi">PRIVATE</span>
                <br />
                <span className="font-recia">EVENTS</span>
              </h2>
              <hr className="border-maroon w-1/3 mx-auto mt-12" />
            </div>

            <p className="text-base md:text-lg font-satoshi max-w-md mb-6 text-center">
              Host your next unforgettable gathering at Enoteca, where we bring the warmth and charm of an intimate wine bar to your private event. <strong>Whether you're planning a birthday celebration, corporate gathering, or a special get-together with friends,</strong> our venue offers a cozy and inviting atmosphere perfect for any occasion.
            </p>

            <Link
              to="/contact-us"
              className="inline-block bg-olive text-cream font-satoshi font-bold py-3 px-6 rounded-full hover:bg-maroon hover:text-cream transition-colors duration-300 mb-4"
            >
              Contact Us
            </Link>

            {/* Book Now Button */}
            {/* <button
              onClick={() => setBookingModalOpen(true)}
              className="inline-block bg-maroon text-cream font-satoshi font-bold py-3 px-6 rounded-full hover:bg-olive hover:text-cream transition-colors duration-300"
            >
              Book Now
            </button> */}
          </div>

          {/* Right Column: Image & Stain Holder */}
          <div className="md:w-1/2 mt-8 md:mt-0 md:pr-8 relative flex justify-center order-1 md:order-1">
            <div className="relative w-full">
              <WineStainHolder className="w-full h-auto object-contain pointer-events-none" />
            </div>
          </div>
        </div>
      </div>

      {/* Booking Modal */}
      <BookingModal
        isOpen={isBookingModalOpen}
        onClose={() => setBookingModalOpen(false)}
      />
    </section>
  );
};

export default PrivateEvents;