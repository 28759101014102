// src/components/CollectiblesSection.tsx

import React from 'react';
import { Link } from 'react-router-dom'; // Adjust the import based on your routing setup

const CollectiblesSection: React.FC = () => {
    return (
        <section className="bg-gold py-24">
            <div className="max-w-4xl mx-auto px-4 text-center">
                {/* Main Heading */}
                <h2 className="text-3xl md:text-4xl font-satoshi text-maroon leading-tight">
                    DON'T MISS OUT ON OUR LIMITED-TIME <br />
                    <span className="font-bold">COLLECTIBLES AND SPECIAL OFFERS</span>
                </h2>

                {/* Subheading */}
                <p className="mt-4 text-cream font-recia text-lg md:text-xl">
                    Whether you're a new visitor or a loyal member, we have something special for you.
                </p>

                {/* Shop Now Button */}
                <div className="mt-8">
                    <Link to="/collectible-wine">
                        <button className="px-6 py-3 bg-maroon text-cream font-satoshi rounded-full hover:bg-olive transition-colors duration-300">
                            Shop Now
                        </button>
                    </Link>
                </div>
            </div>
        </section>
    );
};

export default CollectiblesSection;