// src/stores/InterestStore.ts

import { makeAutoObservable } from 'mobx';
import API from '../api/client';
import { toast } from 'react-toastify';

class InterestStore {
  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  registerInterest = async (wineId: number, email: string) => {
    this.isLoading = true;
    try {
      await API.post('/api/wines/' + wineId + '/interest/', { email });
      toast.success('Thank you! We will notify you when the wine is back in stock.');
    } catch (error) {
      console.error('Error registering interest:', error);
      toast.error('An error occurred. Please try again.');
    } finally {
      this.isLoading = false;
    }
  };
}

const interestStore = new InterestStore();
export default interestStore;