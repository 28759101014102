// src/components/CollectibleWines/CollectiblePurchaseCancel.tsx

import React from 'react';

const CollectiblePurchaseCancel: React.FC = () => {
  return (
    <div className="min-h-screen bg-cream flex items-center justify-center pt-[20vh]">
      <div className="max-w-xl mx-auto p-6 bg-cream shadow-md rounded">
        <h1 className="text-2xl font-bold mb-4 text-center text-maroon font-recia">Purchase Canceled</h1>
        <p className="text-lg text-center text-maroon font-satoshi">
          Your purchase was canceled. If this was a mistake, you can try again.
        </p>
        <div className="mt-6 text-center">
          <a href="/collectible-wine" className="text-maroon underline font-satoshi">
            Back to Collectible Wine
          </a>
        </div>
      </div>
    </div>
  );
};

export default CollectiblePurchaseCancel;