import React from 'react';
import { motion } from 'framer-motion';

const HoursAndLocation: React.FC = () => {
  return (
    <motion.div 
      className="relative z-10 flex flex-col lg:flex-row justify-between items-start w-full px-4 sm:px-6 md:px-8 lg:px-16 py-8 md:py-12 bg-darkBrown bg-opacity-70"
      initial={{ opacity: 0, y: 20 }} // Initial state before animation
      animate={{ opacity: 1, y: 0 }} // Final state after animation
      transition={{ duration: 0.8 }} // Animation duration
    >
      {/* Hours Section */}
      <motion.div 
        className="w-full lg:w-1/2 mb-8 lg:mb-0"
        initial={{ opacity: 0, x: -50 }} // Slide in from the left
        animate={{ opacity: 1, x: 0 }} // Slide to final position
        transition={{ duration: 0.8, delay: 0.2 }} // Delay for staggered animation
      >
        <h2 className="text-xl md:text-2xl font-bold mb-4">Hours</h2>
        <ul className="space-y-8 mr-2">
          <li className="text-center">
            <p className="text-sm md:text-base font-recia text-cream mb-1">Tuesday - Thursday</p>
            <p className="text-2xl md:text-3xl lg:text-4xl font-paperboard-bold">3:00 PM - 9:00 PM</p>
          </li>
          <li className="text-center">
            <p className="text-sm md:text-base font-recia text-cream mb-1">Friday and Saturday</p>
            <p className="text-2xl md:text-3xl lg:text-4xl font-paperboard-bold">3:00 PM - 10:00 PM</p>
          </li>
          <li className="text-center">
            <p className="text-sm md:text-base font-recia text-cream mb-1">Sunday and Monday</p>
            <p className="text-2xl md:text-3xl lg:text-4xl font-paperboard-bold">Open for Private Events</p>
          </li>
        </ul>
      </motion.div>

      {/* Location Section */}
      <motion.div 
        className="w-full lg:w-1/2 flex flex-col items-center"
        initial={{ opacity: 0, x: 50 }} // Slide in from the right
        animate={{ opacity: 1, x: 0 }} // Slide to final position
        transition={{ duration: 0.8, delay: 0.4 }} // Delay for staggered animation
      >
        <h2 className="text-xl md:text-2xl font-bold mb-4">Location</h2>
        <p className="mb-4 text-center">3413 State St, Santa Barbara, California 93105</p>
        <a 
          href="https://www.google.com/maps/dir//3413+State+St,+Santa+Barbara,+CA+93105/@34.4399863,-119.7369598,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x80e914dea0488493:0x649f4169b80ca6da!2m2!1d-119.7369598!2d34.4399863?entry=ttu" 
          target="_blank" 
          rel="noopener noreferrer"
        >
          <motion.img 
            src={"https://enoteca-bucket.s3.amazonaws.com/Location.webp"} 
            alt="Map showing location" 
            className="w-full max-w-sm rounded-lg shadow-lg"
            whileHover={{ scale: 1.05 }} // Scale up slightly on hover
            transition={{ duration: 0.3 }} // Quick transition for hover
          />
        </a>
      </motion.div>
    </motion.div>
  );
};

export default HoursAndLocation;