// src/stores/CollectibleWineStore.ts

import { makeAutoObservable } from 'mobx';
import API from '../api/client';
import { loadStripe } from '@stripe/stripe-js';
import { toast } from 'react-toastify';

const publishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

if (!publishableKey) {
  throw new Error('Stripe publishable key is not defined');
}

const stripePromise = loadStripe(publishableKey);

interface Vintage {
  id: number;
  year: number;
  price: number;
  description: string;
  inventory_count: number;
  image: string;
  stripe_product_id: string;
  stripe_price_id: string;
  is_active: boolean;
  created_at: string;
}

interface GroupedWine {
  baseName: string;
  vintages: Vintage[];
  selectedVintageId?: number;
}

class CollectibleWineStore {
  wines: GroupedWine[] = [];
  isLoading = false;
  error: string | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  fetchWines = async () => {
    this.isLoading = true;
    try {
      const response = await API.get('/api/collectible-wines/');
      const winesData = response.data;

      // Group the wines
      this.wines = groupWines(winesData);

      // Initialize selectedVintageId for each grouped wine
      this.wines.forEach((group) => {
        if (group.vintages.length > 0) {
          group.selectedVintageId = group.vintages[0].id;
        }
      });
    } catch (error) {
      this.error = 'Failed to fetch wines.';
      toast.error(this.error);
    } finally {
      this.isLoading = false;
    }
  };

  selectVintage = (baseName: string, vintageId: number) => {
    const group = this.wines.find((w) => w.baseName === baseName);
    if (group) {
      group.selectedVintageId = vintageId;
    }
  };

  initiatePurchase = async (vintage: Vintage, shippingRequired: boolean) => {
    try {
      const stripe = await stripePromise;
  
      const response = await API.post(`/api/create-wine-checkout-session/${vintage.id}/`, {
        shipping_required: shippingRequired,
      });
  
      const { sessionId } = response.data;
  
      const result = await stripe?.redirectToCheckout({ sessionId });
  
      if (result?.error) {
        console.error(result.error.message);
        toast.error('An error occurred during checkout. Please try again.');
      }
    } catch (error) {
      console.error('Error initiating purchase:', error);
      toast.error('An error occurred while initiating the purchase. Please try again.');
    }
  };
}

const collectibleWineStore = new CollectibleWineStore();
export default collectibleWineStore;

// Helper functions
function extractBaseName(name: string): string {
  const regex = /^(.*?),?\s?\d{4}$/;
  const match = name.match(regex);
  if (match) {
    return match[1];
  }
  return name;
}

function groupWines(wines: any[]): GroupedWine[] {
  const wineMap: { [key: string]: GroupedWine } = {};

  wines.forEach((wine) => {
    const baseName = extractBaseName(wine.name);
    const yearMatch = wine.name.match(/\d{4}$/);
    const year = yearMatch ? parseInt(yearMatch[0]) : null;

    if (!wineMap[baseName]) {
      wineMap[baseName] = {
        baseName,
        vintages: [],
      };
    }

    wineMap[baseName].vintages.push({
      ...wine,
      year: year || 0,
      price: parseFloat(wine.price),
    });
  });

  return Object.values(wineMap);
}