// src/components/CollectibleWines/CollectibleWineItem.tsx

import React, { useState } from 'react';
import collectibleWineStore from '../../stores/CollectibleWineStore';
import interestStore from '../../stores/InterestStore';

interface Vintage {
  id: number;
  year: number;
  price: number;
  description: string;
  inventory_count: number;
  image: string;
  stripe_product_id: string;
  stripe_price_id: string;
  is_active: boolean;
  created_at: string;
}

interface GroupedWine {
  baseName: string;
  vintages: Vintage[];
  selectedVintageId?: number;
}

interface CollectibleWineItemProps {
  wine: GroupedWine;
}

const CollectibleWineItem: React.FC<CollectibleWineItemProps> = ({ wine }) => {
  const [shippingRequired, setShippingRequired] = useState(false);
  const [email, setEmail] = useState('');

  const selectedVintage =
    wine.vintages.find((v: any) => v.id === wine.selectedVintageId) || wine.vintages[0];

  const handlePurchase = () => {
    collectibleWineStore.initiatePurchase(selectedVintage, shippingRequired);
  };

  const handleInterestSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    interestStore.registerInterest(selectedVintage.id, email);
  };

  const handleVintageSelect = (vintageId: number) => {
    collectibleWineStore.selectVintage(wine.baseName, vintageId);
  };

  return (
    <div className="text-center px-4">
      {/* Title */}
      <h2 className="text-xl md:text-2xl font-recia text-maroon">{wine.baseName}</h2>

      {/* Vintage Selector */}
      {wine.vintages.length > 1 && (
        <div className="mt-4">
          <p className="text-sm font-satoshi text-maroon mb-2">Select Vintage:</p>
          <div className="flex justify-center space-x-2">
            {wine.vintages.map((vintage: Vintage) => (
              <button
                key={vintage.id}
                onClick={() => handleVintageSelect(vintage.id)}
                className={`px-4 py-2 rounded-full font-satoshi text-sm focus:outline-none ${
                  vintage.id === wine.selectedVintageId
                    ? 'bg-maroon text-cream'
                    : 'bg-cream text-maroon border border-maroon'
                }`}
              >
                {vintage.year}
              </button>
            ))}
          </div>
        </div>
      )}

      {/* Display Price and Description */}
      {selectedVintage && (
        <>
          <p className="mt-2 text-base md:text-lg font-satoshi text-maroon">
            ${selectedVintage.price}
          </p>
          <p className="mt-4 text-sm md:text-base text-maroon font-satoshi max-w-md mx-auto">
            {selectedVintage.description}
          </p>
        </>
      )}

      {selectedVintage &&
        selectedVintage.inventory_count < 5 &&
        selectedVintage.inventory_count > 0 && (
          <p className="text-red-500 mt-2 font-satoshi">
            Only {selectedVintage.inventory_count} left in stock!
          </p>
        )}

      {selectedVintage && selectedVintage.inventory_count > 0 ? (
        <>
          {/* Shipping vs. Pickup Options */}
          <div className="mt-6 flex justify-center space-x-6">
            <label className="inline-flex items-center">
              <input
                type="radio"
                className="form-radio text-maroon"
                name={`shipping_${wine.baseName}`}
                value="false"
                checked={!shippingRequired}
                onChange={() => setShippingRequired(false)}
              />
              <span className="ml-2 text-maroon font-satoshi">Pick Up</span>
            </label>
            <label className="inline-flex items-center">
              <input
                type="radio"
                className="form-radio text-maroon"
                name={`shipping_${wine.baseName}`}
                value="true"
                checked={shippingRequired}
                onChange={() => setShippingRequired(true)}
              />
              <span className="ml-2 text-maroon font-satoshi">Ship ($35)</span>
            </label>
          </div>

          {/* Buy Now Button */}
          <button
            className="mt-6 bg-maroon text-cream py-3 px-8 rounded-full font-satoshi text-lg"
            onClick={handlePurchase}
          >
            Buy Now
          </button>
        </>
      ) : (
        <div className="mt-6">
          <span className="text-gray-500 font-satoshi">Out of Stock</span>
          <form onSubmit={handleInterestSubmit} className="mt-4 flex justify-center">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Your Email"
              required
              className="border border-maroon text-maroon rounded-l-full py-2 px-4"
            />
            <button
              type="submit"
              className="bg-maroon text-cream py-2 px-6 rounded-r-full font-satoshi"
            >
              Notify Me
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default CollectibleWineItem;