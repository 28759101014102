import React from "react";
import EventsHero from "./EventsHero";
import FeaturedEvent from "./FeaturedEvent";
import WeeklyEvents from "./WeeklyEvents";
import PrivateEvents from "./PrivateEvents";
import UpcomingEventsCalendar from "./UpcomingEventsCalendar";

const Events: React.FC = () => {
    return (
        <>
            <EventsHero/>
            <FeaturedEvent />
            <WeeklyEvents />
            <PrivateEvents />
            <UpcomingEventsCalendar />
        </>
    )
}

export default Events;