// stores/EventsStore.ts
import { makeAutoObservable, runInAction } from 'mobx';
import API from '../api/client';

export interface Event {
  id: number;
  title: string;
  description: string;
  date: string;
  pdf: string | null;
  // No image field since events don't include images
}

class EventsStore {
  events: Event[] = [];
  isLoading = false;
  error: string | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  fetchEvents = async () => {
    this.isLoading = true;
    this.error = null;
    try {
      const response = await API.get<Event[]>('/api/featured-events/');
      const data = await response.data;
      runInAction(() => {
        this.events = data;
        this.isLoading = false;
      });
    } catch (error: any) {
      runInAction(() => {
        this.error = error.message;
        this.isLoading = false;
      });
    }
  };
}

const eventsStore = new EventsStore();
export default eventsStore;