// RsvpStore.ts
import { makeAutoObservable, runInAction } from 'mobx';
import API from '../api/client';
import { toast } from 'react-toastify';

class RsvpStore {
  name: string = '';
  email: string = '';
  phone_number: string = '';
  message: string = '';
  guests: number = 1; // New field
  isSubmitting: boolean = false;
  success: boolean = false;
  error: string | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setName(name: string) {
    this.name = name;
  }

  setEmail(email: string) {
    this.email = email;
  }

  setPhoneNumber(phone: string) {
    this.phone_number = phone;
  }

  setMessage(message: string) {
    this.message = message;
  }

  setGuests(guests: number) {
    this.guests = guests;
  }

  async submitRsvp(eventId: number) {
    this.isSubmitting = true;
    this.error = null;
    this.success = false;

    try {
      const response = await API.post(`/api/events/${eventId}/rsvp`, {
        name: this.name,
        email: this.email,
        phone_number: this.phone_number,
        message: this.message,
        guests: this.guests, // Include guests in the payload
      });

      if (response.status === 201) {
        runInAction(() => {
          this.success = true;
          this.reset();
        });
        toast.success('RSVP successful! Thank you.');
      } else {
        runInAction(() => {
          this.error = 'Unexpected response from the server.';
        });
        toast.error('Unexpected response from the server.');
      }
    } catch (err: any) {
      const errorMsg = err.response?.data?.error || 'Failed to submit RSVP.';
      runInAction(() => {
        this.error = errorMsg;
      });
      toast.error(errorMsg);
    } finally {
      runInAction(() => {
        this.isSubmitting = false;
      });
    }
  }

  reset() {
    this.name = '';
    this.email = '';
    this.phone_number = '';
    this.message = '';
    this.guests = 1; // Reset guests to 1
    this.isSubmitting = false;
    this.success = false;
    this.error = null;
  }
}

const rsvpStore = new RsvpStore();
export default rsvpStore;