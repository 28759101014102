// src/components/UpcomingEventsCalendar.tsx
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import eventsStore, { Event as ApiEvent } from '../../stores/EventsStore';
import RSVPModal from './RSVPModal';
import { weeklyEvents } from './WeeklyEvents'; 
import { weeklyEventDisplayInfo } from './WeeklyEvents';

// Types for display events
interface DisplayEvent {
  id: number;
  title: string;
  description: string;
  date: Date;
  pdf: string | null;
  isWeekly: boolean;
  isSpecial: boolean;
  weekdayTime: string;
  dateLabel: string;
  
  bgColor: string;
  textColor: string;
  bannerBg: string;
  bannerTextColor: string;
  price?: string;
  buttonBg?: string;
  buttonText?: string;
  showRSVP?: boolean;
}

function parseEventDate(dateStr: string): Date {
  return new Date(dateStr);
}

function formatDate(d: Date): string {
  return d.toLocaleString('en-US', {
    timeZone: 'America/Los_Angeles',
    month: 'short',
    day: 'numeric'
  });
}

function getWeekdayTimeFromDate(d: Date): string {
  const weekday = d.toLocaleString('en-US', { timeZone: 'America/Los_Angeles', weekday: 'long' });
  const time = d.toLocaleString('en-US', { timeZone: 'America/Los_Angeles', hour: 'numeric', minute: 'numeric', hour12: true });
  return `${weekday} ${time}`;
}

// Weekly logic
function getNextWeeklyOccurrence(we: ApiEvent, fromDate: Date = new Date()): Date {
  const parts = we.date.split(' ');
  const weekdayPart = parts[0]; 
  const timePart = parts[1];   
  const ampmPart = parts[2];   

  const weekdayName = weekdayPart.replace(/s$/, '');
  const weekdayMap: { [key: string]: number } = {
    Sunday:0, Monday:1, Tuesday:2, Wednesday:3, Thursday:4, Friday:5, Saturday:6
  };
  const targetWeekday = weekdayMap[weekdayName] ?? 0;

  const [startHourStr] = timePart.split('-');
  let startHour = parseInt(startHourStr, 10) || 17; 
  let startMinute = 0;
  if (ampmPart.toLowerCase().includes('p.m.') && startHour < 12) {
    startHour += 12;
  }

  const now = new Date(fromDate.getTime());
  const day = now.getDay();
  let diff = targetWeekday - day;
  if (diff < 0) diff += 7;

  const currentHour = now.getHours();
  const currentMinute = now.getMinutes();
  if (diff === 0) {
    if (currentHour > startHour || (currentHour === startHour && currentMinute > startMinute)) {
      diff += 7;
    }
  }

  const result = new Date(now);
  result.setDate(now.getDate() + diff);
  result.setHours(startHour, startMinute, 0, 0);
  return result;
}

function getWeekdayTimeFromWeeklyDesc(desc: string): string {
  const parts = desc.split(' ');
  const weekday = parts[0].replace(/s$/, '');
  return `${weekday} ${parts.slice(1).join(' ')}`;
}

function getMultipleUpcomingWeeklyOccurrences(we: ApiEvent, count: number): DisplayEvent[] {
  const info = weeklyEventDisplayInfo[we.id];
  const occurrences: DisplayEvent[] = [];
  let start = new Date();
  for (let i = 0; i < count; i++) {
    const occ = getNextWeeklyOccurrence(we, start);
    occurrences.push({
      id: we.id,
      title: we.title.toUpperCase(),
      description: we.description,
      date: occ,
      pdf: we.pdf,
      isWeekly: true,
      isSpecial: false,
      weekdayTime: getWeekdayTimeFromWeeklyDesc(we.date),
      dateLabel: formatDate(occ),
      price: info.priceOrDiscount || undefined,
      bgColor: info.backgroundColor,
      textColor: 'text-cream',
      bannerBg: info.timeBoxColor,
      bannerTextColor: info.timeBoxTextColor,
      buttonBg: '',
      buttonText: '',
      showRSVP: false
    });
    const nextStart = new Date(occ.getTime() + 60000);
    start = nextStart;
  }
  return occurrences;
}

function generateUpcomingEvents(specialEvents: ApiEvent[], weeklyEventsList: ApiEvent[]): DisplayEvent[] {
  const now = new Date();

  const specialEventDisplays: DisplayEvent[] = specialEvents.map((se: ApiEvent) => {
    const eventDate = parseEventDate(se.date);
    return {
      id: se.id,
      title: se.title.toUpperCase(),
      description: se.description,
      date: eventDate,
      pdf: se.pdf,
      isWeekly: false,
      isSpecial: true,
      weekdayTime: getWeekdayTimeFromDate(eventDate),
      dateLabel: formatDate(eventDate),
      bgColor: 'bg-gold',
      textColor: 'text-maroon',
      bannerBg: 'bg-maroon',
      bannerTextColor: 'text-cream',
      price: undefined,
      buttonBg: 'bg-cream',
      buttonText: 'text-maroon',
      showRSVP: true
    };
  });

  let weeklyEventDisplays: DisplayEvent[] = [];
  for (const we of weeklyEventsList) {
    weeklyEventDisplays.push(...getMultipleUpcomingWeeklyOccurrences(we, 1));
  }

  let all = [...specialEventDisplays, ...weeklyEventDisplays];
  all = all.filter(e => e.date.getTime() > now.getTime());
  all.sort((a,b) => a.date.getTime() - b.date.getTime());

  if (all.length < 7) {
    const missing = 7 - all.length;
    let extra: DisplayEvent[] = [];
    for (const we of weeklyEventsList) {
      extra.push(...getMultipleUpcomingWeeklyOccurrences(we, 10));
    }
    extra = extra.filter(e => e.date.getTime() > now.getTime());
    extra.sort((a,b) => a.date.getTime() - b.date.getTime());
    const uniqueExtra = extra.filter(e => !all.some(x => x.id === e.id && x.date.getTime() === e.date.getTime()));
    const merged = [...all, ...uniqueExtra];
    merged.sort((a,b) => a.date.getTime() - b.date.getTime());
    all = merged.slice(0,7);
  } else {
    all = all.slice(0,7);
  }

  const layouts = [
    {
      bgColor: 'bg-gold', textColor: 'text-maroon',
      bannerBg: 'bg-maroon', bannerTextColor: 'text-cream',
      buttonBg: 'bg-cream', buttonText: 'text-maroon'
    },
    {
      bgColor: 'bg-maroon', textColor: 'text-cream',
      bannerBg: 'bg-cream', bannerTextColor: 'text-olive',
      buttonBg: 'bg-gold', buttonText: 'text-darkBrown'
    },
    {
      bgColor: 'bg-olive', textColor: 'text-cream',
      bannerBg: 'bg-cream', bannerTextColor: 'text-olive',
      buttonBg: 'bg-cream', buttonText: 'text-maroon'
    },
    {
      bgColor: 'bg-cream', textColor: 'text-maroon',
      bannerBg: 'bg-maroon', bannerTextColor: 'text-cream',
      buttonBg: 'bg-olive', buttonText: 'text-cream'
    },
    {
      bgColor: 'bg-gold', textColor: 'text-maroon',
      bannerBg: 'bg-cream', bannerTextColor: 'text-maroon',
      buttonBg: 'bg-maroon', buttonText: 'text-cream'
    },
    {
      bgColor: 'bg-gold', textColor: 'text-maroon',
      bannerBg: 'bg-maroon', bannerTextColor: 'text-cream',
      buttonBg: 'bg-cream', buttonText: 'text-maroon'
    },
    {
      bgColor: 'bg-maroon', textColor: 'text-cream',
      bannerBg: 'bg-cream', bannerTextColor: 'text-olive',
      buttonBg: 'bg-gold', buttonText: 'text-darkBrown'
    },
  ];

  return all.map((ev, idx) => {
    const style = layouts[idx];
    const showRSVP = ev.isSpecial && !ev.isWeekly;
    return {
      ...ev,
      ...style,
      showRSVP,
    };
  });
}

const TitleBar = () => (
  <div className="w-full bg-olive py-8 flex flex-col items-center justify-center text-cream px-2">
    {/* Adjust text sizes for mobile using smaller steps */}
    <h1 className="text-2xl sm:text-3xl md:text-4xl font-satoshi uppercase tracking-widest mb-2 text-center">
      UPCOMING
    </h1>
    <h1 className="text-2xl sm:text-3xl md:text-4xl font-recia uppercase tracking-widest text-center">
      EVENTS CALENDAR
    </h1>
  </div>
);

const EventBlock = ({
  event,
  widthClass,
  openRSVP,
}: {
  event: DisplayEvent;
  widthClass: string;
  openRSVP: (eventId: number) => void;
}) => {
  return (
    <div className={`${event.bgColor} ${event.textColor} flex flex-col items-center justify-center relative ${widthClass} h-72 p-4`}>
      <div className="flex flex-col items-center mb-4">
        <div className="text-xl sm:text-2xl font-satoshi font-bold uppercase mb-2">{event.dateLabel}</div>
        <div className={`px-4 py-1 rounded font-bold uppercase ${event.bannerBg} ${event.bannerTextColor} text-xs sm:text-sm text-center`}>
          {event.weekdayTime}
        </div>
      </div>
      <h3 className="text-md sm:text-xl font-recia uppercase tracking-wide text-center mt-2 mb-2 px-2">
        {event.title}
      </h3>
      {event.price && (
        <p className={`font-satoshi text-lg sm:text-2xl font-bold mb-2 ${event.bgColor === 'bg-gold' ? 'text-olive' : 'text-gold'}`}>
          {event.price}
        </p>
      )}
      {event.showRSVP && (
        <button
          onClick={() => openRSVP(event.id)}
          className={`mt-2 px-4 sm:px-6 py-2 rounded-full font-satoshi font-bold uppercase ${event.buttonBg} ${event.buttonText} text-sm sm:text-base`}
        >
          RSVP
        </button>
      )}
    </div>
  );
};

const UpcomingEventsCalendar: React.FC = observer(() => {
  const { events, isLoading, error, fetchEvents } = eventsStore;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState<number | null>(null);

  useEffect(() => {
    fetchEvents();
  }, [fetchEvents]);

  const openRSVPModal = (eventId: number) => {
    setSelectedEventId(eventId);
    setIsModalOpen(true);
  };

  const closeRSVPModal = () => {
    setIsModalOpen(false);
    setSelectedEventId(null);
  };

  if (isLoading) {
    return <div className="py-16 px-4">Loading events...</div>;
  }

  if (error) {
    return <div className="py-16 px-4 text-red-500">{error}</div>;
  }

  // Generate upcoming events
  const displayedEvents = generateUpcomingEvents(events, weeklyEvents);

  const row1 = displayedEvents.slice(0,2);
  const row2 = displayedEvents.slice(2,5);
  const row3 = displayedEvents.slice(5,7);

  return (
    <div className="w-full bg-cream">
      <TitleBar />

      {/* Row 1 (2 events) */}
      <div className="flex flex-wrap w-full">
        {row1[0] && (
          <EventBlock
            event={row1[0]}
            widthClass="w-1/2"
            openRSVP={openRSVPModal}
          />
        )}
        {row1[1] && (
          <EventBlock
            event={row1[1]}
            widthClass="w-1/2"
            openRSVP={openRSVPModal}
          />
        )}
      </div>

      {/* Row 2 (3 events) */}
      <div className="flex flex-wrap w-full">
        {row2[0] && (
          <EventBlock
            event={row2[0]}
            widthClass="w-1/3"
            openRSVP={openRSVPModal}
          />
        )}
        {row2[1] && (
          <EventBlock
            event={row2[1]}
            widthClass="w-1/3"
            openRSVP={openRSVPModal}
          />
        )}
        {row2[2] && (
          <EventBlock
            event={row2[2]}
            widthClass="w-1/3"
            openRSVP={openRSVPModal}
          />
        )}
      </div>

      {/* Row 3 (2 events) */}
      <div className="flex flex-wrap w-full">
        {row3[0] && (
          <EventBlock
            event={row3[0]}
            widthClass="w-1/2"
            openRSVP={openRSVPModal}
          />
        )}
        {row3[1] && (
          <EventBlock
            event={row3[1]}
            widthClass="w-1/2"
            openRSVP={openRSVPModal}
          />
        )}
      </div>

      {selectedEventId && (
        <RSVPModal
          eventId={selectedEventId}
          isOpen={isModalOpen}
          onClose={closeRSVPModal}
        />
      )}
    </div>
  );
});

export default UpcomingEventsCalendar;