// src/components/FeaturedEvent.tsx

import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import eventsStore from '../../stores/EventsStore';
import RSVPModal from './RSVPModal';

const FeaturedEvent: React.FC = observer(() => {
  const { events, isLoading, error, fetchEvents } = eventsStore;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState<number | null>(null);

  useEffect(() => {
    fetchEvents();
  }, [fetchEvents]);

  if (isLoading) {
    return (
      <section className="py-16 px-4">
        <p>Loading events...</p>
      </section>
    );
  }

  if (error) {
    return (
      <section className="py-16 px-4">
        <p className="text-red-500">{error}</p>
      </section>
    );
  }

  if (events.length === 0) {
    return null; // Or display a placeholder message
  }

  // Limit to the first three events
  const featuredEvents = events.slice(0, 3);

  const openRSVPModal = (eventId: number) => {
    setSelectedEventId(eventId);
    setIsModalOpen(true);
  };

  const closeRSVPModal = () => {
    setIsModalOpen(false);
    setSelectedEventId(null);
  };

  // Determine responsive columns based on event count
  let columns = 'grid-cols-1';
  if (featuredEvents.length === 2) {
    columns = 'grid-cols-1 md:grid-cols-2';
  } else if (featuredEvents.length === 3) {
    columns = 'grid-cols-1 md:grid-cols-2 lg:grid-cols-3';
  }

  return (
    <section className="relative bg-cream text-darkBrown py-16 px-4 sm:px-6 md:px-8 lg:px-16 font-satoshi">
      {/* Featured Events */}
      <div className={`relative w-full -mt-24 grid ${columns} gap-6 place-items-center`}>
        {featuredEvents.map((event, index) => (
          <div
            key={event.id}
            className={`bg-gold text-cream shadow-lg flex flex-col justify-between rounded-lg min-h-[400px] max-w-sm w-full`}
          >
            {/* Event Title Bar */}
            <div
              className={`text-center py-4 rounded-t-lg ${
                index % 2 === 0 ? 'bg-maroon' : 'bg-olive'
              }`}
            >
              <h2 className="text-xl font-satoshi font-bold">{event.title}</h2>
            </div>
            {/* Event Content */}
            <div className="p-4 flex-1 flex flex-col items-center justify-center">
              <p className="text-base mb-2 text-center">{event.description}</p>
              <p className="text-base mb-4 font-bold text-center">
                {new Date(event.date).toLocaleString('en-US', {
                  timeZone: 'America/Los_Angeles',
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: true,
                })}
              </p>
              {event.pdf && (
                <a
                  href={event.pdf}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="px-4 py-2 bg-darkBrown text-cream text-lg font-bold rounded-full hover:bg-maroon hover:text-darkBrown transition-colors duration-300 mb-4"
                >
                  Read More (PDF)
                </a>
              )}
            </div>
            {/* RSVP Button */}
            <div className="pb-4">
              <button
                onClick={() => openRSVPModal(event.id)}
                className="w-11/12 mx-auto block py-2 bg-maroon text-cream font-bold text-center hover:bg-olive transition-colors duration-300 rounded-sm border-2 border-cream"
              >
                RSVP
              </button>
            </div>
          </div>
        ))}
      </div>
      {/* RSVP Modal */}
      {selectedEventId && (
        <RSVPModal
          eventId={selectedEventId}
          isOpen={isModalOpen}
          onClose={closeRSVPModal}
        />
      )}
    </section>
  );
});

export default FeaturedEvent;