import React from "react";
import HomeHero from "./HomeHero";
import AboutUs from "./AboutUs";
import JoinWineClub from "./JoinWineClub";
import TrifoldTwo from "./TrifoldTwo";
import PrivateEvents from "../Events/PrivateEvents";
import TestimonialsSection from "./TestimonialsSection";
import CollectiblesSection from "./CollectiblesSection";

const Home: React.FC = () => {
    return (
        <>
        <HomeHero />
        <AboutUs />
        <JoinWineClub />
        <TrifoldTwo/>
        <PrivateEvents />
        <TestimonialsSection />
        <CollectiblesSection />
        </>
    )
}

export default Home;